import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LoginViewModel } from '@/types/LoginViewModel';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class LoginService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.login = this.login.bind(this);
    }

    public login(dto: LoginViewModel): Promise<any> {
        return this.post(`admin/login`, dto)
            .then((result: AxiosResponse<any>) => {
                return result
            });
    }
}

export const loginApi = new LoginService(apiConfig);
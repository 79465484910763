<template>
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">LOGGA IN</div>
                    <div class="card-body">
                        <form @submit.prevent="login">
                            <div class="mb-3">
                                <label for="email" class="form-label">E-postadress</label>
                                <input type="email"
                                       class="form-control"
                                       v-model="email"
                                       required />
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Lösenord</label>
                                <input type="password"
                                       class="form-control"
                                       v-model="password"
                                       required />
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="rememberMeCheckbox" v-model="rememberMe">
                                <label class="form-check-label" for="rememberMeCheckbox">Kom ihåg mig</label>
                            </div>
                            <button type="submit" class="btn btn-primary w-100">LOGGA IN</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { loginApi } from '@/services/login.service';

    const router = useRouter();

    const email = ref('');
    const password = ref('');
    const rememberMe = ref(true);

    const login = async () => {
        try {
            const response = await loginApi.login({
                email: email.value,
                password: password.value,
                rememberMe: rememberMe.value
            });
            localStorage.setItem('authToken', response.data.token);
            router.push('/admin/articles');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                router.push('/login');
            }
        }
    };
</script>
